<template>
  <div id="UserSecurityTab" class="">
    <!-- Password form for current user -->
    <div v-if="$store.state.auth.id === userId">
      <validation-observer ref="setPasswordValidation">
        <b-form>
          <b-form-input
            id="username"
            v-model="user.username"
            name="username"
            autocomplete="username"
            autocapitalize="off"
            autocorrect="off"
            type="text"
            required
            hidden
          />

          <b-form-group
            label="Current Password"
            label-for="current-password"
          >
            <validation-provider #default="{ errors }" name="Current Password" rules="required">
              <b-form-input
                id="current-password"
                v-model="oldPassword"
                :disabled="userFederationEnabled"
                name="current-password"
                autocomplete="current-password"
                autocapitalize="off"
                autocorrect="off"
                type="password"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="New Password"
            label-for="password"
          >
            <validation-provider #default="{ errors }" name="New Password" rules="required">
              <b-form-input
                id="new-password"
                v-model="newPassword"
                :disabled="userFederationEnabled"
                name="new-password"
                autocomplete="new-password"
                type="password"
                autocapitalize="off"
                autocorrect="off"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Confirm Password"
            label-for="confirm-password"
          >
            <validation-provider #default="{ errors }" name="Confirm Password" rules="required|max-length-254">
              <b-form-input
                id="confirm-password"
                v-model="passwordConfirm"
                :disabled="userFederationEnabled"
                name="confirm-password"
                autocomplete="new-password"
                type="password"
                autocapitalize="off"
                autocorrect="off"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="mt-2 d-inline-flex w-100 justify-content-end">
            <b-button
              type="reset"
              variant="flat-secondary"
              size="sm"
              class="mr-50"
              :disabled="loading"
            >
              Clear
            </b-button>
            <b-button
              type="submit"
              variant="success"
              size="sm"
              :disabled="loading || !(oldPassword && newPassword && newPassword === passwordConfirm)"
              @click="setPassword"
            >
              Set Password
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>

    <!-- Password form for administrators -->
    <div v-else>
      <!-- Send Password Reset Email -->
      <div>
        <div class="d-inline-flex w-100 justify-content-between">
          <p>
            Send a password reset email to this user
            (<span class="text-primary select-all">{{ user.email }}</span>).
            Password reset emails will expire after 12 hours.
          </p>
        </div>
        <div class="flex-column align-content-end">
        <b-button
          size="sm"
          variant="info"
          :disabled="sendingResetEmailLoading || userFederationEnabled"
          @click="sendPasswordResetEmail"
        >
          <b-spinner v-if="sendingResetEmailLoading" small class="mr-50" />
          <span v-if="!sendingResetEmailLoading">Send password reset email</span>
          <span v-else>Sending password reset email</span>
        </b-button>
      </div>
      </div>

      <hr class="my-2">

      <p>
        Set a temporary password for this user, the user will be prompted to change their password on their next login.
      </p>

      <validation-observer ref="temporaryPasswordValidation">
        <b-form>
          <b-form-input
            id="username"
            v-model="user.username"
            :readonly="userFederationEnabled"
            name="username"
            autocomplete="username"
            autocapitalize="off"
            autocorrect="off"
            type="text"
            required
            hidden
          />

          <b-form-group
            label="New Temporary Password"
            label-for="password"
          >
            <validation-provider #default="{ errors }" name="New Temporary Password" rules="required">
              <b-form-input
                id="new-password"
                v-model="newPassword"
                :readonly="userFederationEnabled"
                name="new-password"
                autocomplete="new-password"
                type="password"
                autocapitalize="off"
                autocorrect="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Confirm Temporary Password"
            label-for="confirm-password"
          >
            <validation-provider #default="{ errors }" name="Confirm Temporary Password" rules="required|max-length-254">
              <b-form-input
                id="confirm-password"
                v-model="passwordConfirm"
                :readonly="userFederationEnabled"
                name="confirm-password"
                autocomplete="new-password"
                type="password"
                autocapitalize="off"
                autocorrect="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-inline-flex w-100 justify-content-between">
            <div>
              <h6 class="text-info">
                Password policy
              </h6>
              <ul v-if="passwordPolicy.length > 0">
                <li v-for="rule in passwordPolicy" :key="rule">
                  {{ rule }}
                </li>
              </ul>
              <p v-else>
                None
              </p>
            </div>
            <div>
              <b-button
                type="reset"
                variant="flat-secondary"
                size="sm"
                class="mr-50"
                :disabled="loading"
              >
                Clear
              </b-button>
              <b-button
                type="submit"
                variant="success"
                size="sm"
                :disabled="userFederationEnabled || loading || !(newPassword && newPassword === passwordConfirm)"
                @click="setTemporaryPassword"
              >
                Set Temporary Password
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'UserSecurityTab',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userFederationEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const oldPassword = ref(null)
    const newPassword = ref(null)
    const passwordConfirm = ref(null)
    const passwordPolicy = ref([])
    const fetchPasswordPolicy = async () => {
      passwordPolicy.value = await coreService.adminCliApi.getPasswordPolicy()
    }
    fetchPasswordPolicy()

    const setPassword = () => {
      if (oldPassword.value && newPassword.value && newPassword.value === passwordConfirm.value) {
        loading.value = true
        const payload = {
          old_password: oldPassword.value,
          new_password: newPassword.value,
        }
        // Nb. We can't use the method commented out below
        //  because we want meaningful reponses for toast content
        // coreService.userManagementApi.changePassword(props.userId, payload)
        coreService.put(
          `/auth/user/${props.userId}/change-password`,
          payload,
        )
          .then(response => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'User password updated',
                icon: 'UserCheckIcon',
                variant: 'success',
              },
            })
            oldPassword.value = ''
            newPassword.value = ''
            passwordConfirm.value = ''
            setPasswordValidation.value.reset()
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to update password',
                text: `${error?.response?.data?.detail ?? ''}`,
                icon: 'UserXIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { loading.value = false })
      }
    }
    const setTemporaryPassword = () => {
      if (newPassword.value && newPassword.value === passwordConfirm.value) {
        loading.value = true
        coreService.put(`/auth/user/${props.userId}/set-temporary-password`, newPassword.value)
          .then(response => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Set temporary password for user',
                icon: 'UserCheckIcon',
                variant: 'success',
              },
            })
            newPassword.value = ''
            passwordConfirm.value = ''
            temporaryPasswordValidation.value.reset()
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to set temporary password',
                text: `${error?.response?.data?.detail ?? ''}`,
                icon: 'UserXIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { loading.value = false })
      }
    }
    const sendingResetEmailLoading = ref(false)
    const sendPasswordResetEmail = () => {
      sendingResetEmailLoading.value = true
      coreService.userManagementApi.sendPasswordResetEmail(props.userId)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Password reset email sent',
              text: `${props.user.username}`,
              icon: 'SendIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to send password reset email',
              text: `${error}`,
              icon: 'SendIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { sendingResetEmailLoading.value = false })
    }

    // Form validation extensions
    const setPasswordValidation = ref(null)
    const temporaryPasswordValidation = ref(null)
    extend('min-length-5', value => {
      if (value.length >= 5) {
        return true
      }
      return '{_field_} must be at least 5 characters'
    })

    return {
      passwordPolicy,
      setPasswordValidation,
      temporaryPasswordValidation,
      oldPassword,
      newPassword,
      passwordConfirm,
      loading,
      sendingResetEmailLoading,

      setPassword,
      setTemporaryPassword,
      sendPasswordResetEmail,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
