<template>
  <div id="UserEventsTab" class="">
    <b-card no-body class="w-100">
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">

          <!-- Sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="1"
            label-for="sortBySelect"
            label-class="mt-50 mr-50 pl-0"
            class="mb-0 text-nowrap"
            style="min-width: 20rem"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <!-- ./Sorting  -->

          <!-- Search -->
          <b-form-group
            label="Search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            label-class="mt-50"
            class="mb-0"
            style="width: 30rem"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder=""
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- ./Search -->
        </div>
      </b-card-body>

      <b-table
        responsive
        striped
        hover
        show-empty
        class="position-relative"
        :items="events"
        :fields="eventsTableFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="item => $set(item, '_showDetails', !item._showDetails)"
      >
        <template #cell(time)="{ item }">
          <span :title="$options.filters.formatDate(item.time)">
            {{ item.time | diffForHumansMaxTwoWeeks }}
          </span>
        </template>

        <template #row-details="{ item }">
          <pre>{{ item.details }}</pre>
        </template>

        <template #empty>
          <div class="text-center my-2">
            <p>
              This user has no recorded events.
            </p>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- Page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-class="mt-50"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <!-- ./Page length -->

        <!-- Pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <!-- ./Pagination -->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, onMounted, ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import {
  BPagination,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'UserEventsTab',
  components: { BPagination, BTable },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => {
      fetchEvents()
    })

    const eventsTableFields = [
      { key: 'time', label: 'Time', sortable: true },
      { key: 'type', label: 'Event Type', sortable: true },
      { key: 'sessionId', label: 'Session ID', sortable: true },
      { key: 'ipAddress', label: 'IP Address', sortable: true },
      { key: 'clientId', label: 'Client ID', sortable: true },
    ]
    const events = ref([])
    const fetchEvents = async () => {
      coreService.userManagementApi.getUserEvents(props.userId)
        .then(response => { events.value = response })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch user events',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    }

    const perPage = 20
    const pageOptions = [10, 20, 50, 100]
    const totalRows = computed(() => events.value.length)
    const currentPage = 1
    const sortBy = 'time'
    const sortDesc = true
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const sortOptions = eventsTableFields
      .filter(f => f.sortable)
      .map(f => ({ text: f.label, value: f.key }))

    return {
      eventsTableFields,
      events,
      fetchEvents,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      sortOptions,
    }
  },
}
</script>
