<template>
  <component :is="user === undefined ? 'div' : 'b-card'" id="UserProfile">
    <!-- Alert: No user found -->
    <b-alert
      variant="danger"
      :show="user === undefined"
      class="p-2"
    >
      <h4>
        User not found
      </h4>
      <p>
        No user was found with the following ID:
        <span class="ml-1 select-all">{{ $router.currentRoute.params.userId }}</span>
      </p>
    </b-alert>

    <div v-if="user" class="flex-column mb-1">
      <h1 class="mb-0">
        {{ user.firstName }} {{ user.lastName }}
      </h1>
      <span class="select-all text-nowrap">{{ user.username }}</span>
    </div>

    <b-tabs v-if="user" lazy>
      <!-- Tab: Account Details -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Details</span>
        </template>
        <user-details-tab
          :user="user"
          :user-federation-enabled="userFederationEnabled"
          class="pt-75"
          @fetch-user="fetchUser"
        />
      </b-tab>

      <!-- Tab: Security -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Security</span>
        </template>
        <user-security-tab
          :user-id="userId"
          :user="user"
          :user-federation-enabled="userFederationEnabled"
          class="pt-75"
        />
      </b-tab>

      <!-- Tab: Groups & Roles (Admin only) -->
      <b-tab v-if="$store.state.auth.roles.includes('administrator')">
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Groups & Roles</span>
        </template>
        <user-groups-roles-tab
          :user-id="userId"
          :user-federation-enabled="userFederationEnabled"
          class="pt-75"
        />
      </b-tab>

      <!-- Tab: Sessions (Admin only) -->
      <b-tab v-if="$store.state.auth.roles.includes('administrator')">
        <template #title>
          <feather-icon
            icon="ChromeIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Sessions</span>
        </template>
        <user-sessions-tab
          :user-id="userId"
          class="pt-75"
        />
      </b-tab>

      <!-- Tab: Events -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ActivityIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Events</span>
        </template>
        <user-events-tab
          :user-id="userId"
          class="pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>

</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import coreService from '@/libs/api-services/core-service'
// import UserAttributesTab from '@/auth/views/admin/UserManagement/Tabs/UserAttributesTab.vue'
import UserDetailsTab from '@/auth/views/admin/UserManagement/Tabs/UserDetailsTab.vue'
import UserEventsTab from '@/auth/views/admin/UserManagement/Tabs/UserEventsTab.vue'
import UserGroupsRolesTab from '@/auth/views/admin/UserManagement/Tabs/UserGroupsRolesTab.vue'
import UserSecurityTab from '@/auth/views/admin/UserManagement/Tabs/UserSecurityTab.vue'
import UserSessionsTab from '@/auth/views/admin/UserManagement/Tabs/UserSessionsTab.vue'

export default {
  name: 'UserPage',
  components: {
    // UserAttributesTab,
    UserDetailsTab,
    UserEventsTab,
    UserGroupsRolesTab,
    UserSecurityTab,
    UserSessionsTab,
  },
  setup(props, context) {
    const userFederationEnabled = ref(false)
    onMounted(() => { getUserFederation() })
    const getUserFederation = () => {
      coreService.adminCliApi.getUserFederationConfig()
        .then(response => {
          userFederationEnabled.value = response.enabled[0].toLowerCase() === 'true'
        })
    }

    // Fetch User data
    const userId = ref(null)
    const user = ref(null)
    const fetchUser = () => {
      user.value = null
      coreService.userManagementApi.getUser(router.currentRoute.params.userId)
        .then(response => {
          user.value = response
          userId.value = user.value.id
        })
        .catch(error => {
          if (error.response.status === 404) {
            user.value = undefined
          }
        })
    }
    fetchUser()

    return {
      userFederationEnabled,
      userId,
      user,
      fetchUser,
    }
  },
}
</script>
