var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UserGroupsRolesTab"}},[_c('div',[_c('div',{staticClass:"d-inline-flex w-100 justify-content-between"},[_vm._m(0),_c('div',{staticClass:"flex-column align-content-end"},[(_vm.userRoles.length > 0)?_c('b-button',{staticClass:"text-nowrap",attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.$bvModal.show('AssignRolesToUser')}}},[_vm._v(" Assign role ")]):_vm._e()],1)]),_c('b-table',{staticClass:"mt-50",attrs:{"fields":_vm.fieldsRoleTable,"items":_vm.userRoles,"responsive":"","striped":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"flat-danger","title":"Unassign role from user","disabled":['default-roles-kompozition', 'uma_authorization'].includes(item.name)},on:{"click":function($event){return _vm.unassignRole(item)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}})],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v(" This user has no assigned roles. Assign a role to get them started. ")]),_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.$bvModal.show('AssignRolesToUser')}}},[_vm._v(" Assign role ")])],1)]},proxy:true}])})],1),_c('hr',{staticClass:"my-2"}),_c('div',[_c('div',{staticClass:"d-inline-flex w-100 justify-content-between"},[_vm._m(1),_c('div',{staticClass:"flex-column align-content-end"},[(_vm.userGroups.length > 0)?_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.$bvModal.show('AssignGroupsToUser')}}},[_vm._v(" Assign group ")]):_vm._e()],1)]),_c('b-table',{staticClass:"mt-50",attrs:{"fields":_vm.fieldsGroupTable,"items":_vm.userGroups,"responsive":"","striped":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"flat-danger","title":"Unassign group from user"},on:{"click":function($event){return _vm.unassignGroup(item)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}})],1)]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.name))])]}},{key:"cell(realmRoles)",fn:function(ref){
var item = ref.item;
return _vm._l((item.realmRoles),function(role,index){return _c('dl',{key:index,staticClass:"m-0"},[_c('dt',[_vm._v("- "+_vm._s(role))])])})}},{key:"cell(clientRoles)",fn:function(ref){
var item = ref.item;
return _vm._l((item.clientRoles),function(clientRoleList,client,index){return _c('dl',{key:index,staticClass:"m-0"},[_c('dt',{class:index > 0 ? 'mt-50' : ''},[_vm._v(" "+_vm._s(client)+" ")]),_vm._l((clientRoleList),function(role,index){return _c('dd',{key:index,staticClass:"ml-1 my-0"},[_vm._v(" - "+_vm._s(role)+" ")])})],2)})}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v(" This user has no assigned groups. ")]),_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.$bvModal.show('AssignGroupsToUser')}}},[_vm._v(" Assign group ")])],1)]},proxy:true}])})],1),_c('div',[_c('assign-roles-to-user',{on:{"on-submit":_vm.assignRoles}}),_c('assign-groups-to-user',{on:{"on-submit":_vm.assignGroups}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Role membership")]),_c('span',[_vm._v("Assign/unassign roles for the given user. Role permissions apply platform-wide. "),_c('code',[_vm._v("default-roles-kompozition")]),_vm._v(" and "),_c('code',[_vm._v("uma_authorization")]),_vm._v(" are mandatory.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Group membership")]),_c('span',[_vm._v("A group is a collection of users that share common attributes and roles.")])])}]

export { render, staticRenderFns }