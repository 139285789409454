<template>
  <div id="UserGroupsRolesTab" class="">
    <!-- Roles -->
    <div>
      <div class="d-inline-flex w-100 justify-content-between">
        <div>
          <h3>Role membership</h3>
          <span>Assign/unassign roles for the given user. Role permissions apply platform-wide. <code>default-roles-kompozition</code> and <code>uma_authorization</code> are mandatory.</span>
        </div>

        <div class="flex-column align-content-end">
          <b-button
            v-if="userRoles.length > 0"
            size="sm"
            variant="info"
            class="text-nowrap"
            @click="$bvModal.show('AssignRolesToUser')"
          >
            Assign role
          </b-button>
        </div>
      </div>
      <b-table
        :fields="fieldsRoleTable"
        :items="userRoles"
        responsive
        striped
        show-empty
        class="mt-50"
      >
        <template v-slot:cell(actions)="{ item }">
          <b-button
            variant="flat-danger"
            title="Unassign role from user"
            :disabled="['default-roles-kompozition', 'uma_authorization'].includes(item.name)"
            @click="unassignRole(item)"
          >
            <feather-icon
              icon="TrashIcon"
              class="text-danger"
            />
          </b-button>
        </template>

        <template #empty>
          <div class="text-center my-2">
            <p>
              This user has no assigned roles. Assign a role to get them started.
            </p>
            <b-button
              size="sm"
              variant="info"
              @click="$bvModal.show('AssignRolesToUser')"
            >
              Assign role
            </b-button>
          </div>
        </template>
      </b-table>
    </div>

    <hr class="my-2">

    <!-- Groups -->
    <div>
      <div class="d-inline-flex w-100 justify-content-between">
        <div>
          <h3>Group membership</h3>
          <span>A group is a collection of users that share common attributes and roles.</span>
        </div>

        <div class="flex-column align-content-end">
          <b-button
            v-if="userGroups.length > 0"
            size="sm"
            variant="info"
            @click="$bvModal.show('AssignGroupsToUser')"
          >
            Assign group
          </b-button>
        </div>
      </div>

      <b-table
        :fields="fieldsGroupTable"
        :items="userGroups"
        responsive
        striped
        show-empty
        class="mt-50"
      >
        <template v-slot:cell(actions)="{ item }">
          <b-button
            variant="flat-danger"
            title="Unassign group from user"
            @click="unassignGroup(item)"
          >
            <feather-icon
              icon="TrashIcon"
              class="text-danger"
            />
          </b-button>
        </template>

        <template v-slot:cell(name)="{ item }">
          <span class="text-nowrap">{{ item.name }}</span>
        </template>

        <template v-slot:cell(realmRoles)="{ item }">
          <dl v-for="(role, index) in item.realmRoles" :key="index" class="m-0">
            <dt>- {{ role }}</dt>
          </dl>
        </template>

        <template v-slot:cell(clientRoles)="{ item }">
          <dl v-for="(clientRoleList, client, index) in item.clientRoles" :key="index" class="m-0">
            <dt :class="index > 0 ? 'mt-50' : ''">
              {{ client }}
            </dt>
            <dd v-for="(role, index) in clientRoleList" :key="index" class="ml-1 my-0">
              - {{ role }}
            </dd>
          </dl>
        </template>

        <template #empty>
          <div class="text-center my-2">
            <p>
              This user has no assigned groups.
            </p>
            <b-button
              size="sm"
              variant="info"
              @click="$bvModal.show('AssignGroupsToUser')"
            >
              Assign group
            </b-button>
          </div>
        </template>
      </b-table>
    </div>

    <!-- Modals -->
    <div>
      <assign-roles-to-user @on-submit="assignRoles" />
      <assign-groups-to-user @on-submit="assignGroups" />
    </div>
  </div>
</template>

<script>
import AssignGroupsToUser from '@/auth/views/admin/UserManagement/Modals/AssignGroupsToUser.vue'
import AssignRolesToUser from '@/auth/views/admin/UserManagement/Modals/AssignRolesToUser.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onMounted, ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'UserGroupsRolesTab',
  components: {
    AssignGroupsToUser,
    AssignRolesToUser,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => {
      fetchUserRoles()
      fetchUserGroups()
    })

    // Roles
    const fieldsRoleTable = [
      { key: 'name', label: 'Role', thStyle: 'width: 30rem' },
      { key: 'description', label: 'Description' },
      { key: 'actions', label: '' },
    ]
    const userRoles = ref([])
    const fetchUserRoles = async () => {
      coreService.userManagementApi.getUserRoles(props.userId)
        .then(response => { userRoles.value = response })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch user roles',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    }
    const unassignRole = async role => {
      coreService.userManagementApi.removeUserRoles(props.userId, [role.name])
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unassigned role from user',
              text: `${role.name}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to unassign role from user',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { fetchUserRoles() })
    }
    const assignRoles = roles => {
      if (roles.length > 0) {
        coreService.userManagementApi.addUserRoles(props.userId, roles)
          .then(response => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: `Assigned ${roles.length} roles to user`,
                text: `${roles}`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to assign role to user',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { fetchUserRoles() })
      }
    }

    // Groups
    const fieldsGroupTable = [
      { key: 'name', label: 'Group' },
      { key: 'realmRoles', label: 'Realm Roles' },
      { key: 'clientRoles', label: 'Client Roles' },
      // { key: 'attributes', label: 'Attributes' },
      // { key: 'subGroups', label: 'Child groups' },
      { key: 'actions', label: '', thStyle: 'width: 1rem' },
    ]
    const userGroups = ref([])
    const fetchUserGroups = async () => {
      coreService.userManagementApi.getUserGroups(props.userId)
        .then(response => { userGroups.value = response })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch user groups',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    }
    const assignGroups = groups => {
      if (groups.length > 0) {
        coreService.userManagementApi.addGroupsToUser(props.userId, groups)
          .then(response => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: `Assigned ${groups.length} groups to user`,
                text: `${groups}`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to assign user to group',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { fetchUserGroups() })
      }
    }
    const unassignGroup = async group => {
      coreService.userManagementApi.removeUserGroup(props.userId, group.id)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Removed user from group',
              text: `${group.name}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to remove user from group',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { fetchUserGroups() })
    }

    return {
      userRoles,
      fieldsRoleTable,
      fetchUserRoles,
      assignRoles,
      unassignRole,

      userGroups,
      fieldsGroupTable,
      assignGroups,
      unassignGroup,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
