<template>
  <b-modal
    id="AssignRolesToUser"
    title="Assign roles to user"
    size="lg"
    centered
    ok-variant="success"
    ok-title="Assign roles"
    cancel-variant="outline-secondary"
    cancel-title="Discard"
    @show="fetchAllRoles"
    @hidden="onClose"
    @ok="onSubmit"
  >
    <b-table
      :fields="fieldsRoleTable"
      :items="allRoles"
      responsive
      striped
      show-empty
      class="mt-50"
    >
      <template #head(actions)>
        <b-form-checkbox
          v-model="checkboxAll"
          class="custom-control-info"
          @change="updateSelectedRoles"
        />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-form-checkbox
          v-model="item.selected"
          class="custom-control-info"
          @change="updateSelectedRoles(item)"
        />
      </template>

      <template v-slot:cell(name)="{ item }">
        <span class="text-nowrap">{{ item.name }}</span>
      </template>
    </b-table>

    <p v-if="checkboxAll" class="text-warning">
      <span class="font-weight-bold">Warning:</span> All roles are selected, are you sure?
    </p>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AssignRoleToUser',
  components: {},
  setup(props, context) {
    const fieldsRoleTable = [
      { key: 'actions', label: '' },
      { key: 'name', label: 'Role', thStyle: 'width: 5rem' },
      { key: 'description', label: 'Description' },
    ]
    const checkboxAll = ref(false)
    const selectedRoles = ref([])
    const allRoles = ref([])

    watch(checkboxAll, newVal => { selectAllRoles(newVal) })
    const selectAllRoles = selectAll => {
      allRoles.value.forEach(role => {
        role.selected = selectAll
      })
      updateSelectedRoles()
    }
    const updateSelectedRoles = () => {
      selectedRoles.value = allRoles.value
        .filter(role => role.selected)
        .map(role => role.name)
    }

    const fetchAllRoles = async () => {
      coreService.roleManagementApi.getAllRoles()
        .then(response => { allRoles.value = response })
    }
    const onSubmit = () => {
      context.emit('on-submit', selectedRoles.value)
    }
    const onClose = () => {
      allRoles.value = []
      selectedRoles.value = []
      checkboxAll.value = false
    }

    return {
      fieldsRoleTable,
      allRoles,
      selectedRoles,
      checkboxAll,
      fetchAllRoles,
      onSubmit,
      onClose,
      selectAllRoles,
      updateSelectedRoles,
    }
  },
}
</script>
