<template>
  <div id="UserDetailsTab" class="">
    <b-form class="mt-0">
      <b-form-group
        label="UUID"
        label-for="uuid"
      >
        <p id="uuid" class="mt-50 mb-2 text-muted text-nowrap select-all">
          {{ user.id }}
        </p>
      </b-form-group>

      <b-form-group
        label="Username"
        label-for="username"
      >
        <p id="username" class="mt-50 mb-2 text-muted text-nowrap select-all">
          {{ user.username }}
        </p>
      </b-form-group>

      <validation-observer ref="userValidation">
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider #default="{ errors }" name="Email" rules="required|email|max-length-254">
              <b-form-input
                v-model="localUser.email"
                :readonly="userFederationEnabled"
                name="email"
                placeholder="taylor.smith@acme.com"
                type="email"
                autocapitalize="off"
                autocorrect="off"
                :formatter="value => value.toLowerCase()"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="First name"
            label-for="first-name"
          >
            <validation-provider #default="{ errors }" name="First Name" rules="required|max-length-254">
              <b-form-input
                v-model.trim="localUser.firstName"
                :readonly="userFederationEnabled"
                name="firstName"
                placeholder="Taylor"
                autocapitalize="on"
                autocorrect="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Last name"
            label-for="last-name"
          >
            <validation-provider #default="{ errors }" name="Last Name" rules="required|max-length-254">
              <b-form-input
                v-model.trim="localUser.lastName"
                :readonly="userFederationEnabled"
                name="lastName"
                placeholder="Smith"
                autocapitalize="on"
                autocorrect="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
      </validation-observer>
    </b-form>

    <div class="mt-2 d-inline-flex w-100 justify-content-end">
      <b-button
        type="submit"
        variant="outline-secondary"
        size="sm"
        class="mr-50"
        :disabled="loading"
        @click="resetForm"
      >
        Reset
      </b-button>
      <b-button
        type="submit"
        variant="success"
        size="sm"
        :disabled="loading || userFederationEnabled"
        @click="updateUser"
      >
        Save changes
      </b-button>
    </div>
  </div>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

export default {
  name: 'UserDetailsTab',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userFederationEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const localUser = ref(null)
    const setUser = () => {
      if (props.user) {
        localUser.value = { ...props.user }
      }
    }
    watch(props.user, () => setUser())
    setUser()

    const loading = ref(false)
    const updateUser = async () => {
      const userValid = await userValidation.value.validate()

      if (userValid) {
        loading.value = true
        const payload = {
          firstName: localUser.value.firstName,
          lastName: localUser.value.lastName,
          email: localUser.value.email,
        }

        coreService.userManagementApi.updateUser(props.user.id, payload)
          .then(response => {
            context.emit('fetch-user')
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'User updated',
                icon: 'UserCheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to update user',
                text: `${error}`,
                icon: 'UserXIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { loading.value = false })
      }
    }
    const resetForm = () => { context.emit('fetch-user') }

    // Form validation extensions
    const userValidation = ref(null)
    extend('min-length-5', value => {
      if (value.length >= 5) {
        return true
      }
      return '{_field_} must be at least 5 characters'
    })
    extend('max-length-254', value => {
      if (value.length <= 254) {
        return true
      }
      return '{_field_} must be less than 254 characters'
    })
    extend('email', value => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (emailRegex.test(value)) {
        return true
      }
      return 'Invalid email format'
    })

    return {
      localUser,
      userValidation,
      resetForm,
      updateUser,
      loading,
    }
  },
}
</script>
