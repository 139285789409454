<template>
  <b-modal
    id="AssignGroupsToUser"
    title="Assign groups to user"
    size="lg"
    centered
    ok-variant="success"
    ok-title="Assign groups"
    cancel-variant="outline-secondary"
    cancel-title="Discard"
    @show="fetchAllGroups"
    @hidden="onClose"
    @ok="onSubmit"
  >
    <b-table
      :fields="fieldsGroupTable"
      :items="allGroups"
      responsive
      striped
      show-empty
      class="mt-50"
    >
      <template #head(actions)>
        <b-form-checkbox
          v-model="checkboxAll"
          class="custom-control-info"
          @change="updateSelectedGroups"
        />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-form-checkbox
          v-model="item.selected"
          class="custom-control-info"
          @change="updateSelectedGroups(item)"
        />
      </template>

      <template v-slot:cell(name)="{ item }">
        <span class="text-nowrap">{{ item.name }}</span>
      </template>

      <template v-slot:cell(realmRoles)="{ item }">
        <dl v-for="(role, index) in item.realmRoles" :key="index" class="m-0">
          <dt>- {{ role }}</dt>
        </dl>
      </template>

      <template v-slot:cell(clientRoles)="{ item }">
        <dl v-for="(clientRoleList, client, index) in item.clientRoles" :key="index" class="m-0">
          <dt :class="index > 0 ? 'mt-50' : ''">
            {{ client }}
          </dt>
          <dd v-for="(role, index) in clientRoleList" :key="index" class="ml-1 my-0">
            - {{ role }}
          </dd>
        </dl>
      </template>
    </b-table>

    <p v-if="checkboxAll" class="text-warning">
      <span class="font-weight-bold">Warning:</span> All groups are selected, are you sure?
    </p>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AssignRoleToUser',
  components: {},
  setup(props, context) {
    const fieldsGroupTable = [
      { key: 'actions', label: '', thStyle: 'width: 1rem' },
      { key: 'name', label: 'Group', thStyle: 'width: 5rem' },
      { key: 'realmRoles', label: 'Realm Roles' },
      { key: 'clientRoles', label: 'Client Roles' },
      // { key: 'attributes', label: 'Attributes' },
      // { key: 'subGroups', label: 'Child groups' },
    ]
    const checkboxAll = ref(false)
    const selectedGroups = ref([])
    const allGroups = ref([])

    watch(checkboxAll, newVal => { selectAllRoles(newVal) })
    const selectAllRoles = selectAll => {
      allGroups.value.forEach(group => {
        group.selected = selectAll
      })
      updateSelectedGroups()
    }
    const updateSelectedGroups = () => {
      selectedGroups.value = allGroups.value
        .filter(group => group.selected)
        .map(group => group.name)
    }

    const fetchAllGroups = async () => {
      coreService.groupManagementApi.getAllGroups()
        .then(response => { allGroups.value = response })
    }
    const onSubmit = () => {
      context.emit('on-submit', selectedGroups.value)
    }
    const onClose = () => {
      allGroups.value = []
      selectedGroups.value = []
      checkboxAll.value = false
    }

    return {
      fieldsGroupTable,
      allGroups,
      selectedGroups,
      checkboxAll,
      fetchAllGroups,
      onSubmit,
      onClose,
      selectAllRoles,
      updateSelectedGroups,
    }
  },
}
</script>
