var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"AssignGroupsToUser","title":"Assign groups to user","size":"lg","centered":"","ok-variant":"success","ok-title":"Assign groups","cancel-variant":"outline-secondary","cancel-title":"Discard"},on:{"show":_vm.fetchAllGroups,"hidden":_vm.onClose,"ok":_vm.onSubmit}},[_c('b-table',{staticClass:"mt-50",attrs:{"fields":_vm.fieldsGroupTable,"items":_vm.allGroups,"responsive":"","striped":"","show-empty":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('b-form-checkbox',{staticClass:"custom-control-info",on:{"change":_vm.updateSelectedGroups},model:{value:(_vm.checkboxAll),callback:function ($$v) {_vm.checkboxAll=$$v},expression:"checkboxAll"}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"custom-control-info",on:{"change":function($event){return _vm.updateSelectedGroups(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.name))])]}},{key:"cell(realmRoles)",fn:function(ref){
var item = ref.item;
return _vm._l((item.realmRoles),function(role,index){return _c('dl',{key:index,staticClass:"m-0"},[_c('dt',[_vm._v("- "+_vm._s(role))])])})}},{key:"cell(clientRoles)",fn:function(ref){
var item = ref.item;
return _vm._l((item.clientRoles),function(clientRoleList,client,index){return _c('dl',{key:index,staticClass:"m-0"},[_c('dt',{class:index > 0 ? 'mt-50' : ''},[_vm._v(" "+_vm._s(client)+" ")]),_vm._l((clientRoleList),function(role,index){return _c('dd',{key:index,staticClass:"ml-1 my-0"},[_vm._v(" - "+_vm._s(role)+" ")])})],2)})}}])}),(_vm.checkboxAll)?_c('p',{staticClass:"text-warning"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Warning:")]),_vm._v(" All groups are selected, are you sure? ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }